<template>
  <app-cookie-placeholder
    v-if="!gmapsAuthorized"
    :name="t('ttmt.cookies.services.gmaps')"
  />

  <div v-else>
    <div v-if="!isGeocoding && latitude && longitude">
      <iframe
        id="gmap"
        allowfullscreen
        loading="lazy"
        :class="`w-full ${height} rounded-2lg shadow-lg shadow-theme-500/10 bg-white gmap`"
        :src="embedMapUrl"
      />

      <div class="w-full mt-2 flex justify-center items-center flex-wrap">
        <a
          v-if="mapUrl"
          class="text-theme-500 hover:text-theme-700 py-1 px-4 font-bold flex items-center justify-center"
          :href="mapUrl"
          target="_blank"
        >
          <span class="mr-2">
            {{ t('ttmt.common.actions.google_maps') }}
          </span>

          <app-icon
            library="coolicon"
            name="external_link"
          />
        </a>

        <a
          href="#"
          class="text-theme-500 hover:text-theme-700 py-1 px-4 font-bold flex items-center justify-center"
          @click="handleMapPrint('gmap', title)"
        >
          <span class="mr-2">
            {{ t('ttmt.common.actions.print') }}
          </span>

          <app-icon
            library="coolicon"
            name="paragraph"
          />
        </a>
      </div>
    </div>

    <!-- Placeholder map while geocoding -->
    <app-card-item
      v-else-if="isGeocoding"
      :class="`w-full ${height} min-h-fit p-4 flex items-center justify-center flex-col`"
      background-classes="bg-gray-200 text-center"
    >
      <app-icon
        library="fontawesome"
        name="map-location-dot"
        size="6x"
        class="mb-4"
      />

      <p class="font-bold mb-3">
        {{ t('ttmt.points_of_interest.pages.show.is_geocoding_1') }}
      </p>

      <p class="font-bold text-sm">
        {{ t('ttmt.points_of_interest.pages.show.is_geocoding_2') }}
      </p>
    </app-card-item>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isNull } from 'lodash/lang'

import useCookies from '@shared/hooks/cookies'
import usePrintMap from '@extranet/hooks/printMap'
import useLocale from '@shared/hooks/locale'
import AppIcon from '@shared/components/ui/AppIcon.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import AppCookiePlaceholder from '@shared/components/ui/AppCookiePlaceholder.vue'

const props = defineProps({
  // Latitude
  latitude: {
    type: [String, Number],
    default: null,
  },
  // Longitude
  longitude: {
    type: [String, Number],
    default: null,
  },
  // Map zoom level
  zoom: {
    type: [String, Number],
    default: '18',
  },
  // Force specific height
  height: {
    type: String,
    default: 'h-96 md:h-56',
  },
  // Address
  address: {
    type: Object,
    default: null,
  },
  // Print title
  title: {
    type: String,
    default: null,
  },
  // Waiting for geocoding to end
  isGeocoding: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

// Google Embed Map API documentation:
// URL: https://developers.google.com/maps/documentation/embed/embedding-map#place_mode

// ---------- GOOGLE MAPS LINK ----------

const gmapsBaseUrl = 'https://www.google.com/maps'

const mapUrl = computed(() => {
  let gmapsParams = `?ll=${props.latitude},${props.longitude}&zoom=${props.zoom}`
  if (!isNull(props.address)) {
    const gmapsAddress = getGoogleMapsAddress(props.address)
    gmapsParams += `&q=${gmapsAddress}`
  }

  return `${gmapsBaseUrl}${gmapsParams}`
})

// ---------- GOOGLE MAPS EMBED ----------

const gmapsEmbedBaseUrl = `${gmapsBaseUrl}/embed/v1/place`
const gmapsEmbedApiKey = import.meta.env.VITE_GOOGLE_MAPS_EMBED_API_KEY

const embedMapUrl = computed(() => (
  `${gmapsEmbedBaseUrl}?q=${props.latitude},${props.longitude}&key=${gmapsEmbedApiKey}&zoom=${props.zoom}`
))

// ---------- GOOGLE MAPS ADDRESS ----------

function getGoogleMapsAddress(address) {
  const params = []

  if (address?.attributes.line1) {
    params.push(getAttributeTranslation(address.attributes.line1)
      .toString())
  }

  if (address?.attributes.zip) {
    params.push(address.attributes.zip
      .toString())
  }

  if (address?.attributes.city) {
    params.push(getAttributeTranslation(address.attributes.city)
      .toString())
  }

  if (address?.attributes.country) {
    params.push(t(`ttmt.countries.${address.attributes.country}`)
      .toString())
  }

  return encodeURIComponent(params.join(','))
}

// ---------- PRINT ----------

const { handleMapPrint } = usePrintMap()

// ---------- COOKIE ----------

const { getCookieSetting } = useCookies()

const gmapsAuthorized = computed(() => (
  getCookieSetting('gmaps')
))
</script>
